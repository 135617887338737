<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <el-page-header @back="back" :content="isEdit ? '编辑领域' : '创建领域'">
      </el-page-header>
      <el-button type="primary" @click="onSubmit"> 提交 </el-button>
    </div>
    <div class="container">
      <el-form ref="form" v-model="circleData" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="图标">
              <div style="display: flex">
                <el-upload action="" :before-upload="(file) => uploadIcon(file, 'icon')" v-loading="uploading['icon']">
                  <el-avatar :size="80" :src="circleData.icon"></el-avatar>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="背景图">
              <div style="display: flex">
                <el-upload action="" :before-upload="(file) => uploadIcon(file, 'bgUrl')" v-loading="uploading['bgUrl']">
                  <el-avatar :size="80" :src="circleData.bgUrl"></el-avatar>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="二维码">
              <div style="display: flex">
                <el-upload action="" :before-upload="(file) => uploadIcon(file, 'qrCode')" v-loading="uploading['qrCode']">
                  <el-avatar :size="80" :src="circleData.qrCode"></el-avatar>
                </el-upload>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="名称" required>
          <div style="display: flex">
            <el-input v-model="circleData.name" style="width: 500px" placeholder="请输入圈子名称"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="管理员名称">
          <div style="display: flex">
            <el-input v-model="circleData.managerName" style="width: 500px" placeholder="请输入管理员名称, 不填则为取选择的管理员名称"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="管理员">
          <div style="display: flex">
            <el-select v-model="circleData.userId" style="width: 500px" placeholder="请选择管理员, 不选则默认为创建人">
              <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="简介">
          <div style="display: flex">
            <el-input
              type="textarea"
              v-model="circleData.descriptions"
              style="width: 500px"
              :rows="5"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import FieldApi from "@/request/FieldApi";
import FileUtil from "@/utils/FileUtil";
import UserApi from "@/request/UserApi.js";
export default {
  data() {
    return {
      circleId: null,
      circleData: {},
      dialogVisible: false,
      page: 1,
      totalRows: 0,
      isEdit: false,
      uploading: {
        icon: false,
        qrCode: false,
        bgUrl: false,
      },
      userList: []
    };
  },
  mounted() {
    this.circleId = this.$route.query.id;
    if (this.circleId) {
      this.isEdit = true;
      this.getCircleDetail();
    }

    this.loadUserList();
  },
  methods: {
    async loadUserList() {
      const result = await UserApi.userList();
      if (result.success) {
        this.userList = result.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    getCircleDetail() {
      FieldApi.detail({
        id: this.circleId,
      }).then((res) => {
        if (res.success) {
          this.circleData = res.data;
        }
      });
    },
    async uploadIcon(file, type) {
      this.uploading[type] = true;
      const result = await FileUtil.uploadFile(
        {
          file: file,
          dir: "circle",
          rename: true,
        },
        this.$http
      );
      this.uploading[type] = false;
      if (result.success) {
        this.circleData[type] = result.url;
      } else {
        this.$message.error("上传图标失败");
      }
      return false
    },
    async onSubmit() {
      if (!this.circleData.name) {
        this.$message("请填写名称");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
      });
      let result = null;
      if (this.isEdit) {
        result = await FieldApi.update(this.circleData);
      } else {
        result = await FieldApi.insert(this.circleData);
      }
      loading.close();
      if (result.success) {
        this.$message.success(this.circleId ? "修改成功" : "创建成功");
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
</style>
